import { useEffect } from 'react';

import { Banner, BannerBody, BannerIcon, BannerText } from '@playbooks/interface/banners';
import { AccentBtn } from '@playbooks/interface/buttons';
import { useSession, useStorage } from 'contexts';

const AdminBanner = () => {
	const session = useSession();
	const storage = useStorage();

	// Hooks
	useEffect(() => {}, []);

	// Methods
	const onClick = () => {
		storage.removeValue('tempToken');
		window.close();
	};

	// Render
	if (!storage.storage.tempToken.id) return null;
	return (
		<Banner display='flex-between'>
			<BannerBody>
				<BannerIcon type='fad' icon='user-crown' />
				<BannerText>You're acting as {session.user.name}.</BannerText>
			</BannerBody>
			<AccentBtn size='xs' nextIcon='chevron-right' onClick={onClick}>
				Exit
			</AccentBtn>
		</Banner>
	);
};

export { AdminBanner };
