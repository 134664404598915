import { BtnGroup } from '@playbooks/interface/button-groups';
import { AccentBtn } from '@playbooks/interface/buttons';
import { Span } from '@playbooks/interface/html';
import { useTheme } from 'contexts';

const AppThemeToggle = () => {
	const theme = useTheme();

	// Render
	return (
		<BtnGroup divide='' space='space-x-1' spacing='p-1' className='w-full lg:w-auto'>
			<Span width='w-1/3'>
				<AccentBtn
					size='xs'
					icon={{ type: 'fad', icon: 'sun' }}
					alt='toggle light button'
					active={theme.preference === 'light'}
					onClick={() => theme.setPreference('light')}
					className='w-full'
				/>
			</Span>
			<Span width='w-1/3'>
				<AccentBtn
					size='xs'
					icon={{ type: 'fad', icon: 'moon' }}
					alt='toggle dark button'
					active={theme.preference === 'dark'}
					onClick={() => theme.setPreference('dark')}
					className='w-full'
				/>
			</Span>
			<Span width='w-1/3'>
				<AccentBtn
					size='xs'
					icon={{ type: 'fad', icon: 'browser' }}
					alt='toggle system button'
					active={theme.preference === 'system'}
					onClick={() => theme.setPreference('system')}
					className='w-full'
				/>
			</Span>
		</BtnGroup>
	);
};

export { AppThemeToggle };
