import { Div } from '@playbooks/interface/html';
import { SearchDrop } from 'components/search/search-drop';

const AppSearch = () => {
	// Render
	return (
		<Div display='hidden md:block' flex='grow'>
			<SearchDrop
				tableNames={['repos', 'bounties', 'collections', 'teams', 'users']}
				placeholder='Search marketplace...'
				tailwind={{ search: { inputPrepend: { spacing: 'pl-4 px-2' }, input: { spacing: 'px-0 py-2.5' } } }}
			/>
		</Div>
	);
};

export { AppSearch };
