import { useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';

import { Drop, DropMenu } from '@playbooks/interface/drops';
import { Form } from '@playbooks/interface/forms';
import { InputAppend } from '@playbooks/interface/input-groups';
import { appParams } from 'api';
import { SearchResultsSection } from 'components/search/search-results-section';
import { SearchResultsSubnav } from 'components/search/search-results-subnav';
import { useToast } from 'contexts/toast-context';
import { useKeyDownUp } from 'hooks';
import { SearchForm } from 'molecules/forms';
import { AlgoliaService } from 'services';
import { timeout } from 'utils';

const SearchDrop = ({ tableNames, placeholder = 'Search for anything...', rootLink = '', admin = false, tailwind }) => {
	const [query, setQuery] = useState('');
	const [tableName, setTableName] = useState('');
	const [results, setResults] = useState([]);
	const [params, setParams] = useState({ ...appParams, status: 'active', pageSize: 2 });
	const [loading, setLoading] = useState(false);
	const [meta, setMeta] = useState({ page: 0, pageSize: 0, totalRecords: 0 });
	const [open, setOpen] = useState(false);
	const [keys, setKeys] = useState([]);
	const ref = useRef(null);
	const router = useRouter();
	const toast = useToast();

	// Hooks
	useEffect(() => {
		if (query.length >= 3) searchData(query);
	}, [params, query]);

	useEffect(() => {
		if (open) onClose();
	}, [router.pathname]);

	useEffect(() => {
		open ? onReady() : onClear();
	}, [open]);

	useKeyDownUp(onKeyDown, onKeyUp, [open]);

	useEffect(() => {
		if (keys.includes('shift') && keys.includes('k')) onOpen();
	}, [keys]);

	// Methods
	const searchData = async (query = '') => {
		try {
			setLoading(true);
			tableName ? await searchTable() : await searchTables();
		} catch (e) {
			toast.showError(e);
		} finally {
			setLoading(false);
		}
	};

	const searchTable = async () => {
		const client = new AlgoliaService({ tableName });
		const response = await client.queryIndex(query, params);
		setResults(response.data);
		setMeta(response.meta);
	};

	const searchTables = async () => {
		const client = new AlgoliaService({ tableNames });
		const response = await client.queryIndices(query, params);
		setResults(response);
	};

	const onReady = async () => {
		await timeout(300);
		ref.current && ref.current.focus();
	};

	function onKeyDown(e) {
		const newKeys = [];
		if (e.keyCode === 91) newKeys.push('shift');
		if (e.keyCode === 75) newKeys.push('k');
		if (newKeys) setKeys(keys => keys.concat(newKeys));
		if (open && e.keyCode === 38) {
			console.log('up');
		}
		if (open && e.keyCode === 40) {
			console.log('down');
		}
		if (open && e.keyCode === 27) onClear();
	}

	function onKeyUp(e) {
		setKeys([]);
	}

	const onOpen = () => {
		setOpen(true);
	};

	const onSubmit = e => {
		e.preventDefault();
		if (query.length >= 3) router.push(`/search?query=${query}`);
	};

	const onSelect = tableName => {
		setTableName(tableName);
		setParams({ ...params, pageSize: tableName ? 6 : 2 });
	};

	const onClose = () => {
		onClear();
		setOpen(false);
	};

	const onClear = () => {
		setQuery('');
		setResults([]);
		setKeys([]);
	};

	// Render
	return (
		<Drop open={open} setOpen={onClose} width='w-full'>
			<Form onSubmit={onSubmit}>
				<SearchForm
					id='drop'
					ref={ref}
					delay={100}
					query={query}
					setQuery={setQuery}
					placeholder={placeholder}
					onFocus={onOpen}
					onClear={onClear}
					tailwind={tailwind?.search}
					elements={{
						inputAppend: (
							<InputAppend
								border='border'
								borderRadius='rounded-md'
								color='gray-400'
								fontSize='text-xs'
								spacing='m-2 px-2 py-0.5'
								onClick={() => query.length >= 3 && onClear()}>
								{query.length < 3 ? '⌘K' : 'ESC'}
							</InputAppend>
						),
					}}
				/>
			</Form>
			<DropMenu open={open} inset='left-0'>
				<SearchResultsSubnav tableName={tableName} tableNames={tableNames} onSelect={onSelect} />
				<SearchResultsSection
					query={query}
					results={results}
					loading={loading}
					tableName={tableName}
					tableNames={tableNames}
					rootLink={rootLink}
					admin={admin}
				/>
			</DropMenu>
		</Drop>
	);
};

export { SearchDrop };
